import { j as n } from "./jsx-runtime-B6kdoens.js";
import { useState as c } from "react";
import { C as S, c as s } from "./react-select-creatable.esm-BO7cY6b_.js";
import "./Accordion-DtZRzadW.js";
import "./Avatar-95vFNu6x.js";
import { B as v } from "./Badge-C0bj4DFU.js";
import "./Button-BrPm3sL-.js";
import "./DropdownMenu-CjqQt0vp.js";
import { I as C } from "./Icon-C5a5ARPP.js";
import "./Input-vl7Xx2ol.js";
import "./Label-IP-0DoyV.js";
import "./Text-Bn-rHqca.js";
import { T as I } from "./Tooltip-Dy7rqQaX.js";
import { c as f } from "./utils-CJ9afRe1.js";
import "./Stack-DHVjAP0n.js";
import "./Switch-fw2mabqr.js";
import "./Toast-Dk02TeEO.js";
import "./Toggle-Cn0qt44_.js";
const j = () => null, w = (e) => /* @__PURE__ */ n.jsxs(s.Option, { ...e, children: [
  e.children,
  " ",
  e.data.private && /* @__PURE__ */ n.jsx(C, { name: "Lock", width: 16, height: 16 })
] }), M = (e) => /* @__PURE__ */ n.jsx(s.Menu, { ...e, children: e.children }), O = (e) => /* @__PURE__ */ n.jsx(s.MenuList, { ...e, children: e.children }), L = (e) => /* @__PURE__ */ n.jsx(s.MenuPortal, { ...e, "data-toast": "true", children: e.children }), N = (e) => {
  const r = e.getValue().length;
  return /* @__PURE__ */ n.jsxs(s.ValueContainer, { ...e, children: [
    e.children,
    r > 1 && /* @__PURE__ */ n.jsxs(
      v,
      {
        variant: "secondary",
        className: "py-0.5 h-5 group-focus-within/select:hidden mt-1 border border-gray-neutral-80",
        children: [
          "+",
          r - 1,
          " more"
        ]
      }
    )
  ] });
}, d = {
  base: "border-0  rounded-none bg-white hover:cursor-text text-sm",
  focus: "",
  nonFocus: ""
}, P = "text-gray-500 pl-1 group-focus-within/select:py-0.5", E = "pl-1 group-focus-within/select:py-0.5 group-focus-within/select:flex", k = "p-1 gap-1 text-sm group-focus-within/select:pt-1 group-focus-within/select:flex-wrap", z = "leading-7 ml-1", T = "p-1 gap-1 items-start flex flex-row", W = "hidden w-0 overflow-hidden", B = "w-0", F = "p-1 hover:bg-gray-100 text-black-primary rounded-md cursor-pointer", H = "mt-2 border-gray-neutral-80 shadow border bg-white rounded-lg z-dialog max-w-md", D = "text-black-primary text-sm font-medium", u = {
  base: "text-sm truncate overflow-hidden cursor-pointer rounded-md p-2 shrink-0 flex flex-row gap-2 items-center",
  focus: "bg-gray-neutral-30 active:bg-gray-200",
  selected: "text-gray-500"
}, G = "group/select", R = "pl-2 flex flex-col gap-3 text-sm [&>div]:flex [&>div]:flex-col [&>div]:gap-2 [&>div:last-of-type]:pl-2", X = "p-2 flex flex-col gap-2 scrollbar-stable overflow-hidden max-h-56", at = (e) => {
  const [r, h] = c(
    e.options
  ), [g, p] = c(e.value), [i, m] = c(""), x = () => {
    const t = (i == null ? void 0 : i.trim()) || "", o = r.find((l) => l.label === t);
    if (!t || o)
      return;
    const a = {
      label: t,
      value: t,
      type: "NEW",
      private: !1
    };
    h((l) => [...l, a]), p(a), e.onChange(a);
  }, y = (t, { action: o }) => {
    o === "input-change" && m(t), o === "input-blur" && x();
  }, b = (t) => {
    const o = {
      label: (t == null ? void 0 : t.label) ?? "",
      value: (t == null ? void 0 : t.value) ?? "",
      type: (t == null ? void 0 : t.type) ?? "EXISTING",
      private: (t == null ? void 0 : t.private) ?? !1
    };
    p(o), m(o.label), e.onChange(o);
  };
  return /* @__PURE__ */ n.jsx(I.Provider, { children: /* @__PURE__ */ n.jsx(
    S,
    {
      isValidNewOption: () => !1,
      onInputChange: y,
      isClearable: !0,
      closeMenuOnSelect: !0,
      noOptionsMessage: () => null,
      hideSelectedOptions: !1,
      unstyled: !0,
      styles: {
        container: (t) => ({
          ...t,
          width: "100%",
          overflow: "hidden"
        }),
        input: (t) => ({
          ...t,
          "input:focus": {
            boxShadow: "none"
          }
        }),
        valueContainer: (t) => ({
          ...t,
          flexWrap: "nowrap"
        }),
        menu: (t) => ({
          ...t,
          zIndex: 1304
        }),
        menuList: (t) => ({
          ...t,
          maxHeight: void 0
        }),
        option: (t) => ({
          ...t,
          display: "flex",
          fontSize: "14px",
          cursor: "pointer"
        }),
        menuPortal: (t) => ({
          ...t,
          zIndex: 1304,
          pointerEvents: "auto"
        }),
        control: (t) => ({
          ...t,
          transition: "none",
          borderWidth: 0,
          boxShadow: "none"
        })
      },
      components: {
        Option: w,
        Menu: M,
        MenuList: O,
        DropdownIndicator: j,
        ValueContainer: N,
        MenuPortal: L
      },
      classNames: {
        control: ({ isFocused: t }) => f(
          t ? d.focus : d.nonFocus,
          d.base
        ),
        placeholder: () => P,
        input: () => E,
        container: () => G,
        valueContainer: () => k,
        singleValue: () => z,
        indicatorsContainer: () => T,
        clearIndicator: () => W,
        indicatorSeparator: () => B,
        dropdownIndicator: () => F,
        menu: () => H,
        groupHeading: () => D,
        group: () => R,
        menuList: () => X,
        option: ({ isFocused: t, isSelected: o }) => f(
          t && u.focus,
          o && u.selected,
          u.base
        )
      },
      ...e,
      className: "z-dialog",
      options: r,
      value: g,
      onChange: b,
      menuPlacement: "auto",
      menuPosition: "fixed",
      menuPortalTarget: document.body,
      openMenuOnClick: !1,
      openMenuOnFocus: !1,
      tabSelectsValue: !1,
      filterOption: (t) => t.data.type !== "NEW" && t.data.label.toLowerCase().includes(i.toLowerCase())
    }
  ) });
};
export {
  at as default
};
